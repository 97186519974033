.add-fcc-modal .roow.last-row div {
  width: 32%;
}

.add-fcc-modal .roow.last-row .btn-wrap {
  margin-top: 0;
}

.add-fcc-modal .roow.last-row .btn-wrap .icon {
  color: #fff;
  font-size: 24px;
}

.table-outer {
  border: 2px solid #00000010;
}

.add-fcc-modal .btn-wrap.last-step {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-fcc-modal .btn-wrap.last-step button.back {
  background-color: #d71b1b;
}

.steps-wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.steps-wrap div {
  width: 50%;
  border-bottom: 4px solid #00000010;
  text-align: center;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 600;
}

.steps-wrap .step-left.active,
.steps-wrap .step-right.active {
  border-bottom: 4px solid #036323;
  transition: 0.3s;
}

.steps-wrap .step-left.active-text span,
.steps-wrap .step-right.active-text span {
  color: #036323;
  transition: 0.3s;
}

.back-button {
  text-decoration: underline;
  color: #036323;
  cursor: pointer;
  font-size: 16px;
  font-family: "Montserrat";
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .add-fcc-modal .roow.last-row div {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .add-fcc-modal .btn-wrap.last-step {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .back-button {
    margin-bottom: 12px;
  }
  .add-fcc-modal .btn-wrap.last-step button:first-child {
    margin-bottom: 12px;
  }
}
