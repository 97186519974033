.row {
  height: 100%;
}

.row .note {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 15px;
}

.row .note .icon {
  font-size: 24px;
  color: #d71b1b;
  margin-right: 5px;
  margin-top: -2px;
}

.row .note p {
  margin: 0;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #d71b1b;
}

.invoice-container {
  /* overflow-x: auto; */
  border-radius: 16px;
  padding: 10px 0;
  min-width: 1260px;
}

.invoice-container .card {
  min-width: 1240px;
  max-width: 1240px;
  /* min-height: 1754px; */
  height: 1754px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  vertical-align: middle;
}

.invoice-container table {
  width: 100%;
}

.btn {
  width: 220px;
  height: 46px;
  border: none;
  background: #036323;
  border-radius: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #ffffff;
  cursor: pointer;
  /* opacity: 0.4; */
  transition: 0.3s;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.btn-icon {
  width: 40px;
  height: 40px;
  border: none;
  /* background: #036323; */
  background: #f5f5f5;
  border-radius: 6px;
  color: #ffffff;
  background-image: url("../../assets/icons/act-download.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40px 40px;
  cursor: pointer;
  /* position: relative; */
}

.btnWrap {
  position: relative;
}

.iconWrap {
  position: absolute;
  top: 7px;
  left: 7px;
}

.down-btn {
  margin-top: 20px;
  margin-bottom: 20px;
}

.down-btn1 {
  text-align: right;
  position: relative;
}

.tooltip {
  visibility: hidden;
  width: 120px;
  height: 28.5px;
  background-color: #036323;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  bottom: 0;
  top: 6px;
  right: 40px;
  z-index: 1;
}

.btn-icon:hover + .tooltip {
  visibility: visible;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.nowrap {
  white-space: nowrap;
}

.font-bold {
  font-weight: 600;
}

.invoice-info table .border-none td,
.invoice-info table .border-none th {
  border: none;
}

.invoice-info table td,
.invoice-info table th {
  border: 1px solid #000;
  border-collapse: collapse;
  vertical-align: middle;
  text-align: center;
  font-size: 12px;
  line-height: 13px;
}

.invoice-info table td {
  padding: 2px;
}

.nowrap {
  white-space: nowrap;
}

.main-act th {
  padding: 10px;
}
