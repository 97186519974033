.layout-component {
    width: calc(100% - 300px);
    min-height: calc(100vh - 140px);
    background-color: #ffffff;
    border-radius: 10px;
    position: absolute;
    top: 120px;
    left: 280px;
    transition: 0.3s;
}

.layout-component.layout-component-active {
    width: calc(100% - 40px);
    min-height: calc(100vh - 140px);
    background-color: #ffffff;
    border-radius: 10px;
    position: absolute;
    top: 120px;
    left: 20px;
}

@media (max-width: 992px) {
    .layout-component {
        width: calc(100% - 20px);
        min-height: calc(100vh - 140px);
        top: 110px;
        left: 10px;
    }
    .layout-component.layout-component-active {
        width: calc(100% - 20px);
        min-height: calc(100vh - 140px);
        background-color: #ffffff;
        top: 110px;
        left: 10px;
    }  
}

@media (max-width: 576px) {
    .layout-component {
        width: calc(100% - 20px);
        min-height: calc(100vh - 140px);
        top: 130px;
        left: 10px;
    }
    .layout-component.layout-component-active {
        width: calc(100% - 20px);
        min-height: calc(100vh - 140px);
        background-color: #ffffff;
        top: 130px;
        left: 10px;
    } 
}