.agro-main {
  padding: 45px;
  /* display: inline-flex; */
  /* justify-content: center; */
}

.agro-cards {
  display: flex;
  flex-direction: row;
  /* justify-content: flex-start; */
  justify-content: center;
  column-gap: 45px;
  row-gap: 45px;
  flex-wrap: wrap;
}

.agro-cards a {
  text-decoration: none;
}

.agro-cards .agro-card {
  width: 340px;
  height: 232px;
  align-items: center;
  background: linear-gradient(90deg, #0ba360 0%, #3cba92 100%);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
  color: #ffffff;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.agro-cards .agro-card:hover {
  box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.2);
  background: linear-gradient(90deg, #027543 0%, #3b836c 100%);
}

.agro-cards .agro-card p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #ffffff;
  margin-top: 15px !important;
  /* height: 20px; */
}
