.navbar-component {
  width: 100%;
  height: 100px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.navbar-component .menu-icon-wrap {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 350px;
  width: 36px;
  height: 36px;
  border-radius: 8px;
  display: grid;
  place-content: center;
  transition: 0.3s;
  cursor: pointer;
}

.navbar-component .menu-icon-wrap:hover {
  background-color: #03632310;
}

.navbar-component .menu-icon {
  font-size: 32px;
  color: #036323;
}

.navbar-component a {
  text-decoration: none;
}

.navbar-component .left {
  width: 320px;
  display: flex;
  align-items: center;
}

.navbar-component .left .logo {
  width: 68px;
  margin-right: 12px;
}

.navbar-component .left h1 {
  width: 240px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #036323;
  margin: 0;
}

.navbar-component .navbar-center {
  display: flex;
  align-items: center;
}

.navbar-component .navbar-center .icon {
  font-size: 24px;
  color: #d71b1b;
  margin-right: 5px;
  margin-top: -2px;
}

.navbar-component .navbar-center p {
  margin: 0;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #d71b1b;
}

.navbar-component .right {
  display: flex;
  align-items: center;
}

.navbar-component .right .notification {
  margin-right: 30px;
}

.navbar-component .right .langs {
  display: flex;
  margin-right: 46px;
}

.navbar-component .right .langs > div {
  width: 33px;
  display: grid;
  place-content: center;
  border-right: 1px solid #036323;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #03632320;
  cursor: pointer;
}

.navbar-component .right .langs > div:first-child {
  border-right: 1px solid #036323;
}

.navbar-component .right .langs > div:last-child {
  border: none;
}

.navbar-component .right .langs > div.active-lan {
  color: #036323;
}

.navbar-component .right .user {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.navbar-component .right .user .text {
  overflow: hidden;
}

.navbar-component .right .user .text div {
  width: 122.19px;
  overflow: hidden;
  padding-left: 100%;
  box-sizing: content-box;
}

@-webkit-keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.navbar-component .right .user .text h2 {
  display: inline-block;
  white-space: nowrap;
  padding-right: 100%;
  box-sizing: content-box;

  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: ticker;
  animation-name: ticker;
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
}

.navbar-component .right .user .bg {
  width: 36px;
  height: 36px;
  background-image: url("../../assets/icons/user-circle.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 5px;
}

.navbar-component .right .user h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 15px;
  letter-spacing: 0.02em;
  color: #036323;
  margin: 0;
}

.navbar-component .right .user h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.02em;
  color: #036323;
  margin: 0;
}

@media (max-width: 992px) {
  .navbar-component .navbar-center {
    display: none;
  }
}

@media (max-width: 768px) {
  .navbar-component .left {
    width: 280px;
    display: flex;
    align-items: center;
  }
  .navbar-component .left .logo {
    width: 50px;
    margin-right: 12px;
  }
  .navbar-component .left h1 {
    width: 200px;
    font-size: 12px;
    line-height: 16px;
  }
  .navbar-component .menu-icon-wrap {
    left: 300px;
    width: 32px;
    height: 32px;
  }
  .navbar-component .menu-icon-wrap .menu-icon {
    font-size: 28px;
  }
  .navbar-component .right .notification {
    margin-right: 5px;
    margin-left: 30px;
  }
  .navbar-component .right .langs {
    margin-right: 10px;
  }
  .navbar-component .right .langs > div {
    width: 30px;
    font-size: 12px;
    line-height: 14px;
  }
  .navbar-component .right .user .bg {
    width: 30px;
    height: 30px;
    margin-right: 5px;
  }
  .navbar-component .right .user h2 {
    font-size: 14px;
    line-height: 14px;
  }
  .navbar-component .right .user h3 {
    font-size: 12px;
    line-height: 12px;
  }
}

@media (max-width: 576px) {
  .navbar-component {
    height: 120px;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }
  .navbar-component .menu-icon-wrap {
    left: calc(100% - 42px);
    width: 32px;
    height: 32px;
  }
}
