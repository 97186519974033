.list-wrap {
}

.list-wrap .list-item.list-red {
  background: rgba(215, 27, 27, 0.1);
}
.list-wrap .list-item {
  display: flex;
  padding: 20px 30px;
  width: 100%;
  flex: column;
  justify-content: space-between;
  vertical-align: middle;
  align-items: center;
  background-color: #e6efe9;
  margin-bottom: 1px;
  min-height: 86px;
}

.list-wrap .list-item .list-text {
  vertical-align: middle;
}
.list-wrap .list-item .list-text a {
  color: #2a7fff;
  text-decoration: none;
}

.ant-rate-star-full .anticon {
  color: #fbcb07;
}
.ant-rate-star-zero .anticon {
  color: #e6efe9;
}

.ant-rate .ant-rate-star:not(:last-child) {
  margin-right: 14px;
}

.ant-rate-star .anticon {
  font-size: 60px;
}

.rating .ant-rate-star-zero .anticon {
  color: #fff;
}

.rating {
  text-align: center;
  margin-bottom: 50px;
  margin-top: 50px;
}

.rate-wrap {
  max-width: 600px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.rate-wrap p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: #d9d9d9;
  margin-bottom: 48px;
}
.rate-wrap textarea {
  width: 100%;
  border-radius: 6px;
  border: 2px solid #d9d9d9;
  padding: 16px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  margin-bottom: 8px;
}

.list-wrap .list-item .ant-rate-star .anticon {
  font-size: 32px;
}

.list-wrap .ant-rate {
  display: flex;
}

.rate-effect {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  transition: all 0.3s;
  z-index: 10000;
}

.rate-effect.hidden {
  display: none;
}

.rate-effect .smile {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 768px) {
  .ant-rate-star .anticon {
    font-size: 40px;
  }

  .list-wrap .btn {
    width: 150px;
    font-size: 12px;
    height: 38px;
  }

  .list-wrap .list-item .ant-rate-star .anticon {
    font-size: 20px;
  }

  .list-wrap .list-item {
    font-size: 12px;
  }
  .list-wrap .list-item .ant-rate {
    margin-left: 10px;
  }

  .list-wrap .list-item .btn {
    margin-left: 10px;
  }

  .list-wrap .ant-rate .ant-rate-star:not(:last-child) {
    margin-right: 10px;
  }
}

@media (max-width: 576px) {
  .ant-rate-star .anticon {
    font-size: 32px;
  }
}
