.cards-page {
  padding: 35px 60px;
}

.agro-inner-cards {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  row-gap: 35px;
}

.agro-inner-card {
  width: 460px;
  background: linear-gradient(90deg, #f5f7fa 0%, #c3cfe2 100%);
  border-radius: 20px;
  min-height: 210px;
  padding: 35px;
  margin-right: 8px;
  margin-left: 8px;
}

.agro-inner-card.sm {
  width: 290px;
}

.agro-inner-card.sm.flag .bg-img {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 120px;
  margin: auto;
  border-radius: 20px;
}
.agro-inner-card.sm.flag {
  padding: 0;
  height: 170px;
  min-height: 170px;
  width: 190px;
}

.agro-inner-card.sm.flag h3 {
  margin-top: 15px;
}

/* .agro-inner-card.sm.flag img {
  width: 100%;
  height: 120px;
  object-fit: cover;
  object-position: center;
} */

.agro-inner-card.sm .product-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.agro-inner-card:hover {
  background: linear-gradient(90deg, #dddfe4 0%, #aab5c4 100%);
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14);
}

.agro-inner-card .card-info h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.02em;
  max-height: 48px;
  max-width: 390px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.agro-inner-card .card-info .card-text {
  margin-top: 30px;
}

.agro-inner-card .card-info .card-text p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
}

.balance-page .top .filter-icon {
  width: 36px;
  height: 36px;
  mask-image: url("../../assets/icons/filter\ 01.svg");
  background-color: #036323;
  mask-position: center;
  mask-repeat: no-repeat;
  margin-left: 30px;
  cursor: pointer;
}

.agro-inner-cards a {
  text-decoration: none;
}

.agro-inner-card .product-info .card-img {
  text-align: center;
}
.agro-inner-card .product-info .card-img-sm {
  text-align: center;
}

.agro-inner-card .product-info .card-img img {
  max-width: 160px;
}
.agro-inner-card .product-info .card-img-sm img {
  max-width: 80px;
}

.agro-inner-card .product-info h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #111111;
  margin-bottom: 0;
  margin-top: 10px;
}
@media (max-width: 768px) {
  .cards-page {
    padding: 30px 15px;
  }

  .agro-inner-card .card-info h2 {
    font-size: 16px;
  }
}

@media (max-width: 576px) {
  .agro-inner-card {
    width: 350px;
    margin: 0;
    padding: 10px 10px;
  }
}
