.fum-note {
}

.fum-note p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.1em;
  margin-bottom: 30px;
  color: #d71b1b;
}

.fum-note-bottom p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1em;
  margin-bottom: 30px;
  color: #d71b1b;
}

/* .price-text {
  margin-right: auto;
} */
