.balance-page {
  width: 100%;
  padding-bottom: 70px;
}

.balance-page .top {
  width: 100%;
  min-height: 88px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 2px solid #00000010;
}

.balance-page .top h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #036323;
  margin: 0;
  cursor: pointer;
}

.balance-page .filter {
  width: 100%;
  padding: 0 20px;
  height: 0px;
  border-bottom: 2px solid #00000010;
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  background-color: #fff;
}

.balance-page .filter input {
  width: 360px;
  height: 46px;
  border: 2px solid #00000010;
  outline: none;
  border-radius: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #000000;
  padding: 0 20px;
}

.balance-page .filter select {
  width: 360px;
  height: 46px;
  border: 2px solid #00000010;
  outline: none;
  border-radius: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #000000;
  padding: 0 20px;
  appearance: none;
  -webkit-appearance: none;
  background-image: url("../../assets/icons/select.svg");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
}

.balance-page .filter button {
  width: 220px;
  height: 46px;
  background-color: #036323;
  border-radius: 6px;
  border: none;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.balance-page .filter button .icon {
  font-size: 24px;
  margin-right: 10px;
  color: #ffffff;
}

.balance-page .filter.active-filter {
  opacity: 1;
  visibility: visible;
  height: 100px;
}

/* ---------- table ----------- */

.balance-page .table-wrap {
  width: 100%;
  overflow-x: auto;
}

.balance-page .table-wrap table {
  width: 100%;
}

.balance-page .table-wrap table .bg {
  width: 24px;
  height: 24px;
  mask-image: url("../../assets/icons/menu-line-horizontal.svg");
  mask-repeat: no-repeat;
  background-color: #000000;
  cursor: pointer;
  margin: auto;
}

.balance-page .table-wrap table .bg-circle {
  width: 24px;
  height: 24px;
  mask-image: url("../../assets/icons/reload.svg");
  mask-repeat: no-repeat;
  mask-position: center;
  background-color: #000000;
  margin: auto;
}

.balance-page .table-wrap table tr {
  border-bottom: 2px solid #00000010;
  transition: 0.3s;
}

.balance-page .table-wrap table tr a {
  color: #2b7fff;
  text-decoration: none;
}

.balance-page .table-wrap table th {
  text-align: center;
  padding: 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: #000000;
}

.balance-page .table-wrap table td {
  text-align: center;
  padding: 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: #000000;
}

.balance-page .table-wrap table tbody tr:nth-child(2n + 2) {
  background-color: #f6f6f6;
}

.balance-page .table-wrap table tbody tr:hover {
  background-color: #f6f6f6;
}

.table-wrap .more {
  cursor: pointer;
}

.payment-info {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.02em;
  padding-left: 100px;
  padding-right: 100px;
}

.empty-table {
  /* margin-left: auto;
  margin-right: auto; */
  margin-top: 50px;
}

@media (max-width: 1600px) {
  .balance-page .filter input {
    width: 300px;
    height: 40px;
    font-size: 16px;
    padding: 0 10px;
  }
  .balance-page .filter select {
    width: 300px;
    height: 40px;
    font-size: 16px;
    line-height: 100%;
    padding: 0 10px;
  }
  .balance-page .filter button {
    width: 180px;
    height: 40px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }
  .balance-page .filter button .icon {
    font-size: 18px;
  }
}

@media (max-width: 1400px) {
  .balance-page .filter input {
    width: 200px;
    height: 40px;
    font-size: 14px;
    padding: 0 10px;
  }
  .balance-page .filter select {
    width: 200px;
    height: 40px;
    font-size: 14px;
    padding: 0 10px;
  }
  .balance-page .filter button {
    width: 150px;
    height: 40px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }
  .balance-page .filter button .icon {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .payment-info {
    padding-left: 0;
    padding-right: 0;
  }

  .balance-page .top {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }
  .balance-page .top h1 {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 10px;
  }
  .balance-page .top .right {
    width: 100%;
    justify-content: space-between;
  }
  .balance-page .filter {
    height: 0;
    padding: 0 10px;
    flex-direction: column;
    align-items: flex-start;
  }
  .balance-page .filter.active-filter {
    height: auto;
  }
  .balance-page .filter input {
    width: 100%;
    margin: 10px 0;
  }
  .balance-page .filter select {
    width: 100%;
    margin: 10px 0;
  }
  .balance-page .filter button {
    width: 100%;
    margin: 10px 0;
  }
}
