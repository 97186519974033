.guvohnoma-modal * {
  font-family: Montserrat;
}

.guvohnoma-modal .ant-modal-content {
  max-height: 90vh;
  overflow-y: auto;
}

.guvohnoma-modal .ant-modal-content::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.guvohnoma-modal .ant-modal-content::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background: #036323;
  border: 0;
}

.guvohnoma-modal .ant-upload-select {
  display: block !important;
}

.guvohnoma-modal h1 {
  color: #036323;
  text-align: center;
  margin-bottom: 30px;
  font-weight: 600;
}

.guvohnoma-modal label {
  font-weight: 600;
}

.my-modal .ant-modal-content {
  padding-right: 18px;
}
.my-modal .main-content {
  max-height: 70vh;
  overflow-y: auto;
  border-top: 1px solid #f5f5f5;
  border-bottom: 1px solid #f5f5f5;
  padding-top: 16px;
}

.my-modal .main-content::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.my-modal .main-content::-webkit-scrollbar-track {
  box-shadow: #0000000f 0px 2px 4px 0px inset;
  background-color: #00000006;
}
.my-modal .main-content::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background: #036323;
  border: 0;
}

.my-modal h3 {
  color: #036323;
  text-align: center;
  margin-bottom: 30px;
  font-weight: 600;
}

.my-modal label {
  font-weight: 600;
}

.icon-wrap {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
  border: 1px solid #00000030;
  margin-top: 30px;
}

.filter-row {
  display: flex;
  margin-bottom: 20px;
}

.filter-row .ant-select {
  width: 200px;
  margin-right: 10px;
  margin-left: auto;
}

.filter-row .ant-input-group-wrapper {
  width: 200px;
  margin-right: 10px;
}

.roow input[type="file"] {
  overflow: hidden;
  cursor: pointer;
  padding: 0;
}

input[type="file"]::file-selector-button {
  border: none;
  border-radius: 0.2em;
  transition: 0.3s;
  height: 100%;
  cursor: pointer;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
}

input[type="file"]::file-selector-button:hover {
  background-color: rgb(224, 224, 224);
}

.file-input {
  width: 100%;
  height: 40px;
  border: 1px solid #00000030;
  border-radius: 4px;
}
