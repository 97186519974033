.add-fcc-modal .roow > div.input-group {
  width: 32%;
}

.add-fcc-modal .compare-btn {
  margin-top: 30px;
}

@media (max-width: 768px) {
  .add-fcc-modal .roow > div.input-group {
    width: 100%;
    flex-direction: column;
    margin-bottom: 15px;
  }
}
