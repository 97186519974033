.modal-inner {
  font-family: "Montserrat";
}

.modal-inner .warning-wrap {
  font-family: "Montserrat";
  padding: 24px 90px;
  background: rgba(255, 210, 210, 0.3);
  border: 2px solid #d71b1b;
  border-radius: 10px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #d71b1b;
  margin-bottom: 24px;
}

.modal-inner .warning-wrap p {
  margin: 0;
}

.add-fcc-modal .roow > div.form-group {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.roow .form-group .error-select {
  border: 2px solid #d71b1b;
}

label.error-mes {
  font-size: 14px;
  color: #d71b1b;
  margin-top: 4px;
}

.address-wrap {
  /* overflow-x: scroll; */
}

.address-wrap h3 {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 24px;
  margin-top: 32px;
  letter-spacing: 0.02em;
}

.address-wrap td.text-right {
  text-align: right;
  font-weight: 600;
}

.address-wrap td.text-left {
  text-align: left;
  padding-left: 5px;
}

.address-wrap table {
  margin: 0 auto;
  border: 2px solid rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  border-collapse: separate;
  padding: 10px;
}

.addresses {
  /* min-width: 1012px; */
  display: flex;
  flex-direction: row;
}

.addresses .address {
  width: 50%;
  padding: 24px;
  letter-spacing: 0.02em;
}

.addresses .address.address-left {
  border-right: 2px solid rgba(0, 0, 0, 0.15);
}

.addresses .address .address-row {
  display: flex;
  flex-direction: row;
}

.addresses .address .address-row > :nth-child(1) {
  white-space: nowrap;
  margin-right: 14px;
  margin-bottom: 10px;
  font-weight: 600;
}

.address-wrap .refresh-wrap {
  text-align: center;
  text-decoration: underline;
  color: blue;
}

.address-wrap .refresh-wrap span {
  cursor: pointer;
}

.modal-inner {
  position: relative;
  padding-bottom: 70px;
}

.modal-inner .btn-wrap {
  margin-top: 32px;
  margin-bottom: 12px;
  position: absolute;
  bottom: -10px;
}

.condition-wrap {
  margin-top: 28px;
}

.condition-wrap .outer-condition {
  overflow-y: scroll;
  max-height: 400px;
}

.condition-wrap .inner-condition {
  padding-right: 14px;
}

.outer-condition::-webkit-scrollbar {
  width: 14px;
}

.outer-condition::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #e9e9e9;
  background: #e9e9e9;
  border-radius: 100px;
}

.outer-condition::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #f6f6f6;
  background: #f6f6f6;
}

.agree-wrap {
  margin-top: 30px;
}

.agree-check {
  text-align: center;
}

.checkbox-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.agree-check label {
  font-weight: 400;
  cursor: pointer;
}

.agree-check input {
  margin-right: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  color: #28303f;
  display: grid;
  place-content: center;
  border: 1.5px solid #28303f;
  border-radius: 4px;
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.agree-check input::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  /* background-color: #28303f; */
  box-shadow: inset 1em 1em #28303f;
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

.agree-check input:checked::before {
  transform: scale(1);
}

/* .form-group select {
  color: #000000;
}

.form-group option:not(:checked) {
  color: rgba(0, 0, 0, 0.15);
} */

@media (max-width: 530px) {
  .modal-inner .warning-wrap {
    padding: 24px 5px;
    font-size: 14px;
  }
}
