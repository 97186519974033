@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;1,500&display=swap");
/* @font-face {
  font-family: "Montserrat";
  src: url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;1,500&display=swap");
} */

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  background-color: #f5f5f5;
  font-family: "Montserrat" !important;
}

body::-webkit-scrollbar {
  width: 0;
  height: 0;
}

@media print {
  * {
    font-family: "Sans serif";
    /* -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;  */
    /* box-shadow: 0; */
  }
  /* @page {
    size: landscape;
  } */

  .th-heading {
    background-color: #c4c7c7 !important;
    print-color-adjust: exact;
  }

  .product-table th,
  .product-table td {
    width: 12%;
  }

  .invoice-info {
    font-family: "Sans serif";
  }

  .printable-spot {
    /* background-image: url("./assets/images/fss.svg") !important;
    background-repeat: no-repeat;
    background-position: center; */
    print-color-adjust: exact;
  }
  page {
    box-shadow: 0;
    margin: 0;
    margin-bottom: 0;
    /* padding: 15px; */
    box-shadow: none;
    font-family: serif;
    print-color-adjust: exact;
  }
}
.my-segmented label {
  font-weight: 500;
}

.my-segmented label.ant-segmented-item.ant-segmented-item-selected {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}
.my-teble tr {
  cursor: pointer;
}
button {
  text-decoration: none !important;
}
.excel .ant-upload-list-item.ant-upload-list-item-undefined {
  padding: 16px 6px 16px 12px !important;
  border-radius: 8px;
  background-color: rgb(243, 245, 247) !important;
}
.inner-fcc-page {
  width: 100%;
  padding-bottom: 70px;
}

.inner-fcc-page .top {
  min-height: 88px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 2px solid #00000010;
}

.inner-fcc-page .top h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #036323;
  margin: 0;
  cursor: pointer;
}

.inner-fcc-page .top .right {
  display: flex;
  align-items: center;
}

.inner-fcc-page .top .input-wrap {
  width: 460px;
  height: 46px;
  border: 2px solid #00000015;
  border-radius: 6px;
  position: relative;
}

.inner-fcc-page .top .input-wrap > div {
  position: absolute;
  z-index: 2;
  width: 16px;
  height: 16px;
  top: 50%;
  left: 24px;
  transform: translateY(-50%);
  mask-image: url("./assets/icons/search\ 3.svg");
  background-color: #00000060;
}

.inner-fcc-page .top .input-wrap input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #000000;
  padding-left: 56px;
  outline: none;
}

.inner-fcc-page .top .icoon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #00000060;
  font-size: 24px;
  cursor: pointer;
  display: none;
}

.inner-fcc-page .top .icoon.icoon-visible {
  display: block;
}

.inner-fcc-page .top .filter-icon,
.inner-fcc-page .top .add-icon {
  width: 36px;
  height: 36px;
  mask-image: url("./assets/icons/filter\ 01.svg");
  background-color: #036323;
  mask-position: center;
  mask-repeat: no-repeat;
  margin-left: 30px;
  cursor: pointer;
}

.inner-fcc-page .top .add-icon {
  mask-image: url("./assets/icons/plus-circle.svg");
}

.inner-fcc-page .filter {
  width: 100%;
  padding: 0 20px;
  height: 0px;
  border-bottom: 2px solid #00000010;
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  background-color: #fff;
}

.inner-fcc-page .filter input {
  width: 360px;
  height: 46px;
  border: 2px solid #00000010;
  outline: none;
  border-radius: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #00000060;
  padding: 0 20px;
}

.inner-fcc-page .filter select {
  width: 360px;
  height: 46px;
  border: 2px solid #00000010;
  outline: none;
  border-radius: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #00000060;
  padding: 0 20px;
}

.inner-fcc-page .filter button {
  width: 220px;
  height: 46px;
  background-color: #036323;
  border-radius: 6px;
  border: none;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.inner-fcc-page .filter button .icon {
  font-size: 24px;
  margin-right: 10px;
  color: #ffffff;
}

.inner-fcc-page .filter.active-filter {
  opacity: 1;
  visibility: visible;
  height: 100px;
}

.inner-fcc-page .table-wrap {
  width: 100%;
  overflow-x: auto;
}

.inner-fcc-page .table-wrap table {
  width: 100%;
}

.inner-fcc-page .table-wrap table .bg {
  width: 24px;
  height: 24px;
  mask-image: url("./assets/icons/menu-line-horizontal.svg");
  mask-repeat: no-repeat;
  background-color: #000000;
  cursor: pointer;
  margin: auto;
}

.inner-fcc-page .table-wrap table .bg-circle {
  width: 24px;
  height: 24px;
  mask-image: url("./assets/icons/reload.svg");
  mask-repeat: no-repeat;
  mask-position: center;
  background-color: #000000;
  margin: auto;
}

.inner-fcc-page .table-wrap table .bg-circle.bg-circle-editable {
  background-color: #d71b1b;
  cursor: pointer;
}

.inner-fcc-page .table-wrap table tr {
  border-bottom: 2px solid #00000010;
  transition: 0.3s;
}

.inner-fcc-page .table-wrap table tr a {
  color: #036323;
  text-decoration: none;
}

.inner-fcc-page .table-wrap table th {
  text-align: center;
  padding: 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: #000000;
}

.inner-fcc-page .table-wrap table td {
  text-align: center;
  padding: 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: #000000;
}

.inner-fcc-page .table-wrap table tbody tr:nth-child(2n + 2) {
  background-color: #f6f6f6;
}

.inner-fcc-page .table-wrap table tbody tr:hover {
  background-color: #f6f6f6;
}

.inner-fcc-page .table-wrap table tbody tr.is-editable {
  background-color: #ff000030;
}

.inner-fcc-page .bottom-pagination {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 20px;
}

.inner-fcc-page .bottom-pagination button {
  width: 36px;
  height: 36px;
  margin: 0 5px;
  cursor: pointer;
  background-color: #036323;
  color: #ffffff;
  border: none;
  font-size: 18px;
  border-radius: 50%;
  position: relative;
}

.inner-fcc-page .bottom-pagination button .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  margin: 0;
}

.inner-fcc-page .bottom-pagination button.button-disactive {
  opacity: 0.6;
  cursor: not-allowed;
}

.ant-pagination {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 20px;
}

.ant-pagination .ant-pagination-item {
  border-radius: 50%;
  font-family: "Montserrat";
}

.ant-pagination .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination .ant-pagination-next .ant-pagination-item-link {
  border-radius: 50%;
}

.ant-pagination .ant-pagination-item-active:hover {
  border-color: #036323;
}

.ant-pagination .ant-pagination-item-active {
  border-color: #036323;
  background-color: #036323;
}

.ant-pagination .ant-pagination-item-active:hover a {
  color: #fff;
}

.ant-pagination .ant-pagination-item-active a {
  color: #ffffff;
}

.empty-table .ant-empty-description span {
  font-family: "Montserrat";
}

@media (max-width: 1600px) {
  .inner-fcc-page .filter input {
    width: 300px;
    height: 40px;
    font-size: 16px;
    padding: 0 10px;
  }
  .inner-fcc-page .filter select {
    width: 300px;
    height: 40px;
    font-size: 16px;
    line-height: 100%;
    padding: 0 10px;
  }
  .inner-fcc-page .filter button {
    width: 180px;
    height: 40px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }
  .inner-fcc-page .filter button .icon {
    font-size: 18px;
  }
}

@media (max-width: 1400px) {
  .inner-fcc-page .filter input {
    width: 200px;
    height: 40px;
    font-size: 14px;
    padding: 0 10px;
  }
  .inner-fcc-page .filter select {
    width: 200px;
    height: 40px;
    font-size: 14px;
    padding: 0 10px;
  }
  .inner-fcc-page .filter button {
    width: 150px;
    height: 40px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }
  .inner-fcc-page .filter button .icon {
    font-size: 18px;
  }
  .inner-fcc-page .top .input-wrap {
    width: 360px;
    height: 40px;
  }
  .inner-fcc-page .top .input-wrap > div {
    width: 16px;
    height: 16px;
    top: 50%;
    left: 14px;
  }
  .inner-fcc-page .top .input-wrap input {
    font-size: 14px;
    padding-left: 40px;
    outline: none;
  }
}

@media (max-width: 1200px) {
  .inner-fcc-page .top h1 {
    font-weight: 600;
    font-size: 20px;
  }
  .inner-fcc-page .top .input-wrap {
    width: 220px;
    height: 40px;
  }
  .inner-fcc-page .top .filter-icon,
  .add-icon {
    width: 36px;
    height: 36px;
    margin-left: 10px;
  }
}

@media (max-width: 768px) {
  .inner-fcc-page .top {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }
  .inner-fcc-page .top h1 {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 10px;
  }
  .inner-fcc-page .top .right {
    width: 100%;
    justify-content: space-between;
  }
  .inner-fcc-page .filter {
    height: 0;
    padding: 0 10px;
    flex-direction: column;
    align-items: flex-start;
  }
  .inner-fcc-page .filter.active-filter {
    height: auto;
  }
  .inner-fcc-page .filter input {
    width: 100%;
    margin: 10px 0;
  }
  .inner-fcc-page .filter select {
    width: 100%;
    margin: 10px 0;
  }
  .inner-fcc-page .filter button {
    width: 100%;
    margin: 10px 0;
  }

  .inner-fcc-page .top .input-wrap {
    width: 49%;
  }

  .inner-fcc-page .btn-wrap button {
    width: 49%;
    height: 36px;
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .inner-fcc-page .top .right {
    flex-direction: column;
    align-items: flex-end;
  }
  .inner-fcc-page .top .input-wrap {
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
  }
  .inner-fcc-page .top .filter-icon,
  .add-icon {
    margin-left: 10px;
  }
}

.add-fcc-modal {
  overflow: hidden;
}
.add-fcc-modal h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #036323;
  text-align: center;
  margin-bottom: 30px;
}

.add-fcc-modal h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #036323;
  text-align: center;
  margin-bottom: 30px;
}

.add-fcc-modal ul > li {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #000000;
  margin-bottom: 4px;
}

.add-fcc-modal .detail-modal {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #000000;
}

.add-fcc-modal .icon {
  color: #036323;
  font-size: 22px;
}

.add-fcc-modal .roow {
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
}

.add-fcc-modal .roow.row-line {
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 32px;
  margin-top: 32px;
}

.add-fcc-modal .roow > div {
  display: flex;
  width: 48.5%;
  flex-direction: column;
  justify-content: end;
}

.add-fcc-modal label {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #000000;
  margin-bottom: 5px;
}

.add-fcc-modal .roow input {
  width: 100%;
  height: 46px;
  border: 2px solid #00000010;
  border-radius: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #000000;
  padding: 10px;
  outline: none;
}
.add-fcc-modal .roow textarea {
  width: 100%;
  /* height: 46px; */
  border: 2px solid #00000010;
  border-radius: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #000000;
  padding: 10px;
  outline: none;
}

.add-fcc-modal .roow select {
  width: 100%;
  height: 46px;
  border: 2px solid #00000010;
  border-radius: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #000000;
  padding: 10px;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  background-image: url("./assets/icons/select.svg");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
}

.add-fcc-modal .btn-wrap {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

.add-fcc-modal .btn-wrap button {
  width: 220px;
  height: 46px;
  border: none;
  background: #036323;
  border-radius: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #ffffff;
  cursor: not-allowed;
  opacity: 0.4;
  transition: 0.3s;
}

.add-fcc-modal .btn-wrap button.button-active {
  cursor: pointer;
  opacity: 1;
}

.add-fcc-modal .about-product {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
}

.add-fcc-modal .about-product .left {
  width: calc(100% - 70px);
  display: flex;
  justify-content: space-between;
}

.avtoCar-modal input {
  width: 100%;
  height: 36px;
  border: 2px solid #00000010;
  border-radius: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #000000;
  outline: none;
  padding: 10px;
  margin-bottom: 20px;
}

.avtoCar-modal .btn-wrap {
  display: flex;
  justify-content: space-between;
}

.avtoCar-modal .btn-wrap button:first-child {
  background-color: #d71b1b;
}

.avtoCar-modal .btn-wrap button {
  width: 48%;
  height: 32px;
  font-size: 14px;
  background-color: #036323;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.add-fcc-modal .about-product .left input {
  width: 32%;
  height: 46px;
  border: 2px solid #00000010;
  border-radius: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #000000;
  outline: none;
  padding: 10px;
}

.add-fcc-modal .about-product .left input.name-input {
  width: 48%;
}

.add-fcc-modal .about-product .left select {
  width: 32%;
  height: 46px;
  border: 2px solid #00000010;
  border-radius: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #000000;
  outline: none;
  padding: 10px;
}

.add-fcc-modal .about-product .right-btn {
  width: 46px;
  height: 46px;
  display: grid;
  place-content: center;
  background-color: #d71b1b;
  border-radius: 6px;
  cursor: pointer;
}

.add-fcc-modal .about-product .right-btn .icon {
  font-size: 24px;
  color: #ffffff;
}

.add-fcc-modal .about-product1 .right-btn {
  background-color: #036323;
}

.add-fcc-modal .table-wrap {
  width: 100%;
  overflow-x: auto;
}

.add-fcc-modal .table-wrap table {
  width: 100%;
}

.add-fcc-modal .table-wrap table tr {
  border-bottom: 2px solid #00000010;
  transition: 0.3s;
}

.add-fcc-modal .table-wrap table th {
  text-align: center;
  padding: 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: #000000;
}

.add-fcc-modal .table-wrap table td {
  text-align: center;
  padding: 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: #000000;
}

.add-fcc-modal .table-wrap table tbody tr:nth-child(2n + 2) {
  background-color: #f6f6f6;
}

.add-fcc-modal .table-wrap table tbody tr:hover {
  background-color: #f6f6f6;
}

@media (max-width: 768px) {
  .add-fcc-modal .roow {
    flex-direction: column;
    margin-bottom: 0px;
  }
  .add-fcc-modal .roow > div {
    width: 100%;
    flex-direction: column;
    margin-bottom: 15px;
  }
  .add-fcc-modal label {
    font-size: 14px;
  }
  .add-fcc-modal .roow input {
    height: 36px;
    font-size: 14px;
    padding: 5px;
  }
  .add-fcc-modal .roow select {
    height: 36px;
    padding: 5px;
  }
  .add-fcc-modal .btn-wrap button {
    width: 200px;
    height: 36px;
    font-size: 14px;
  }
  .add-fcc-modal .about-product .left {
    width: calc(100% - 50px);
  }
  .add-fcc-modal .about-product .left input {
    width: 32%;
    height: 36px;
    padding: 5px;
  }
  .add-fcc-modal .about-product .left select {
    width: 32%;
    height: 36px;
    padding: 5px;
  }
  .add-fcc-modal .about-product .right-btn {
    width: 36px;
    height: 36px;
  }
  .add-fcc-modal .about-product .right-btn .icon {
    font-size: 18px;
  }

  .add-fcc-modal .about-product .left input.name-input {
    width: 32%;
  }
}
