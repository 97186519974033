/* p {
  margin: 0;
} */
table.doc-table {
  border: 1px solid #000;
  margin-bottom: 10px;
}

table.doc-table th,
table.doc-table td {
  border: 1px solid #000;
}
