.iconn {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px !important;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 5px;
  margin-bottom: 10px;
}

.iconn * {
  font-size: 18px;
  transition: 0.3s;
}

.ant-table-wrapper {
  position: relative;
  padding-bottom: 50px;
}

.ant-table-wrapper .ant-pagination {
  position: absolute;
  bottom: -60px;
}
