@keyframes rotateImg {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.kr-page {
  width: 100%;
  min-height: calc(100vh - 140px);
  padding: 20px;
  display: grid;
  place-content: center;
}

.kr-page .center {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.kr-page .center p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #036323;
  margin-top: 32px;
}

.kr-page .center .img-wrap {
  animation-name: rotateImg;
  animation-duration: 3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
}

/* ---- ------- */

.inner-fcc-page .top-kr {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 2px solid #00000010;
}

.inner-fcc-page .top-kr h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #036323;
  margin: 0;
  cursor: pointer;
}

.inner-fcc-page .top-kr .right {
  display: flex;
  align-items: center;
}

.ant-popover-content button {
  width: 200px;
  height: 36px;
  border: none;
  background: #036323;
  border-radius: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #ffffff;
  cursor: pointer;
  margin-right: 10px;
}

.ant-popover-content button:last-child {
  margin-right: 0;
}

.inner-fcc-page .btn-wrap button {
  width: 220px;
  height: 46px;
  border: none;
  background: #036323;
  border-radius: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #ffffff;
  cursor: not-allowed;
  opacity: 0.4;
  transition: 0.3s;
  margin-left: 15px;
}

.inner-fcc-page .btn-wrap button.button-active {
  opacity: 1;
  cursor: pointer;
}

@media (max-width: 1200px) {
  .kr-page .center p {
    font-size: 36px;
    margin-top: 32px;
  }
  .kr-page .center .img-wrap img {
    width: 70px;
  }

  .inner-fcc-page .top-kr h1 {
    font-weight: 600;
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .inner-fcc-page .btn-wrap button {
    width: 100%;
    height: 36px;
    font-size: 14px;
    margin-left: 0;
  }

  .inner-fcc-page .btn-wrap {
    width: 100%;
  }

  .inner-fcc-page .btn-wrapper {
    width: 49%;
  }
}

@media (max-width: 576px) {
  .kr-page .center p {
    font-size: 24px;
    margin-top: 20px;
    text-align: center;
  }

  .inner-fcc-page .top-kr h1 {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 10px;
  }

  .inner-fcc-page .top-kr {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }

  .inner-fcc-page .top-kr .btn-wrapper a {
    width: 100%;
  }

  .kr-page .center .img-wrap img {
    width: 50px;
  }

  .inner-fcc-page .btn-wrapper {
    width: 100%;
  }

  .inner-fcc-page .btn-wrap button {
    width: 100%;
    margin-left: 0;
  }

  .inner-fcc-page .btn-wrap {
    width: 100%;
  }

  .inner-fcc-page .top-kr .right {
    width: 100%;
    flex-direction: column;
    align-items: flex-end;
  }
}
