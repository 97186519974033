page {
  background: white;
  display: block;
  margin: 0 auto;
  /* margin-bottom: 0.5cm; */
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
  padding: 25px;
}
/* page[size="A4"] {
  width: 21cm;
  height: 29.7cm;
} */

.lab-print-table td,
.lab-print-table th {
  border: 1px solid #000;
  border-collapse: collapse;
  vertical-align: middle;
  text-align: left;
  font-size: 12px;
  line-height: 12px;
  padding: 3px;
  vertical-align: top;
  line-height: 15px;
}

.lab-print-table.custom td,
.lab-print-table.custom th {
  vertical-align: middle;
  text-align: center;
}
.file-container .btn-icon {
  width: 160px;
  height: 40px;
  border: none;
  /* background: #036323; */
  border-radius: 6px;
  background-color: #ffffff;
  color: #036323;
  background-image: url("../../assets/icons/printer.svg");
  background-repeat: no-repeat;
  background-position: 115px;
  background-size: 24px 24px;
  cursor: pointer;
  padding: 8px 25px;
  vertical-align: middle;
  font-size: 15px;
  text-align: left;
  line-height: 20px;
  border: 1px solid #036323;
}

.file-container .down-btn1 {
  text-align: center;
  position: relative;
  margin-top: 15px;
  margin-bottom: 15px;
}

@media print {
  body,
  page {
    background: white;
    margin: 0;
    box-shadow: 0;
  }
}
