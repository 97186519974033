.loader {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: border-color 1s linear;
}

.loader-text {
  position: absolute;
  font-size: 12px;
  color: black;
}
