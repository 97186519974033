@keyframes ldio-grm73x5ykr7 {
  0% { transform: rotate(0) }
  100% { transform: rotate(360deg) }
}

.loader-component {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.ldio-grm73x5ykr7 div { box-sizing: border-box!important }
.ldio-grm73x5ykr7 > div {
  position: absolute;
  width: 149.6px;
  height: 149.6px;
  top: 10.2px;
  left: 10.2px;
  border-radius: 50%;
  border: 13.6px solid #000;
  border-color: #b6b6b6 transparent #b6b6b6 transparent;
  animation: ldio-grm73x5ykr7 1s linear infinite;
}

.ldio-grm73x5ykr7 > div:nth-child(2), .ldio-grm73x5ykr7 > div:nth-child(4) {
  width: 119px;
  height: 119px;
  top: 25.5px;
  left: 25.5px;
  animation: ldio-grm73x5ykr7 1s linear infinite reverse;
}
.ldio-grm73x5ykr7 > div:nth-child(2) {
  border-color: transparent #036323 transparent #036323
}
.ldio-grm73x5ykr7 > div:nth-child(3) { border-color: transparent }
.ldio-grm73x5ykr7 > div:nth-child(3) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}
.ldio-grm73x5ykr7 > div:nth-child(3) div:before, .ldio-grm73x5ykr7 > div:nth-child(3) div:after { 
  content: "";
  display: block;
  position: absolute;
  width: 13.6px;
  height: 13.6px;
  top: -13.6px;
  left: 54.4px;
  background: #b6b6b6;
  border-radius: 50%;
  box-shadow: 0 136px 0 0 #b6b6b6;
}
.ldio-grm73x5ykr7 > div:nth-child(3) div:after {
  left: -13.6px;
  top: 54.4px;
  box-shadow: 136px 0 0 0 #b6b6b6;
}

.ldio-grm73x5ykr7 > div:nth-child(4) { border-color: transparent; }
.ldio-grm73x5ykr7 > div:nth-child(4) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}
.ldio-grm73x5ykr7 > div:nth-child(4) div:before, .ldio-grm73x5ykr7 > div:nth-child(4) div:after {
  content: "";
  display: block;
  position: absolute;
  width: 13.6px;
  height: 13.6px;
  top: -13.6px;
  left: 39.1px;
  background: #036323;
  border-radius: 50%;
  box-shadow: 0 105.39999999999999px 0 0 #036323;
}
.ldio-grm73x5ykr7 > div:nth-child(4) div:after {
  left: -13.6px;
  top: 39.1px;
  box-shadow: 105.39999999999999px 0 0 0 #036323;
}
.loadingio-spinner-double-ring-v4wsvp38mc {
  width: 170px;
  height: 170px;
  display: inline-block;
  overflow: hidden;
  background: rgba(NaN, NaN, NaN, 0);
}
.ldio-grm73x5ykr7 {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-grm73x5ykr7 div { box-sizing: content-box; }