.guvohnoma-page {
  padding: 24px;
}

.card-red {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(90deg, #0f9fd8 0%, #47f9f0 100%);
  padding: 24px;
  border-radius: 10px;
  background: linear-gradient(90deg, #c23a3aef 0%, #f9ac47 100%);
}

.card-blue {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(90deg, #0f9fd8 0%, #47f9f0 100%);
  padding: 24px;
  border-radius: 10px;
}

.balance {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #036323;
}
