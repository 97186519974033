.modal-inner .warning-wrap.common {
  padding: 24px;
  margin-bottom: 30px;
}

.modal-inner.warning {
  padding: 0;
}

.add-fcc-modal h1.warning {
  /* margin-top: 10px; */
}

.add-fcc-modal .btn-wrap button.btn-red {
  background: #eb8d8d;
}

.add-fcc-modal .btn-wrap .btn-red.button-active {
  background-color: #d71b1b;
}

.add-fcc-modal .btn-wrap.warning {
  margin-bottom: 10px;
}

.add-fcc-modal.custom .btn-wrap button.btn-red {
  background: #eb8d8d !important;
  width: 220px;
  height: 46px;
  border-radius: 6px;
  border: none;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #fff;
}

.add-fcc-modal.custom .btn-wrap .btn-red.button-active {
  background-color: #d71b1b !important;
}

.add-fcc-modal.custom .btn-wrap.warning {
  margin-bottom: 10px !important;
}

.langs-outer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.langs {
  display: flex;
  /* margin-right: 46px; */
  justify-content: flex-end;
}

.langs > div {
  width: 33px;
  display: grid;
  place-content: center;
  border-right: 1px solid #036323;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #03632320;
  cursor: pointer;
}

.langs > div:first-child {
  border-right: 1px solid #036323;
}

.langs > div:last-child {
  border: none;
}

.langs > div.active-lan {
  color: #036323;
}

@media (max-width: 768px) {
  .modal-inner .warning-wrap.common p {
    font-size: 13px;
  }
}
.add-fcc-modal.custom.info .modal-inner .warning-wrap {
  border-color: #036323;
  background: #0363231c;
  color: #036323;
}
.add-fcc-modal.custom.info .btn-wrap.warning .button-active.btn-red {
  border-color: #036323 !important;
  background: #036323 !important;
}
