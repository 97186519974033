.instruction-page {
  width: 100%;
}

.instruction-page h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #036323;
  margin: 0;
  cursor: pointer;
  padding: 20px;
  /* margin-bottom: 20px; */
  border-bottom: 1px solid #00000010;
}

.instruction-page .ant-collapse {
  border-radius: 0px;
  border: none;
}

.instruction-page .ant-collapse .ant-collapse-item {
  background-color: #fff;
  border-right: none;
  border-left: none;
  border-radius: 0;
  border-bottom: 1px solid #00000010;
  text-align: center;
}

.instruction-page .ant-collapse .ant-collapse-item .ant-collapse-header {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #111111;
  text-align: left;
}

.instruction-page .ant-collapse .ant-collapse-item p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 0.02em;
  color: #000000;
  text-align: justify;
}

.instruction-page .ant-collapse iframe {
  width: 750px;
  height: 390px;
  border-radius: 14px;
}

@media (max-width: 1200px) {
  .instruction-page .ant-collapse iframe {
    width: 450px;
    height: 220px;
    border-radius: 14px;
  }
}

@media (max-width: 576px) {
  .instruction-page .ant-collapse iframe {
    width: 250px;
    height: 150px;
    border-radius: 14px;
  }
}
